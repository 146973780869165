import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Masonry from 'react-masonry-css';

import Layout from "../components/layout/Layout";
import HubspotForm from "../components/HubspotForm";

import { pushToGTM, postFBEvent } from "../helpers";

const handleLinkClick = (e) => {
    const destUrl = new URL(e.currentTarget.href);
    if (destUrl.origin === window.location.origin
        || destUrl.pathname === window.location.pathname
    ) {
        e.preventDefault();

        const scrollDest = destUrl.href.split('#')[1];
        document.getElementById(scrollDest).scrollIntoView({ behavior: 'smooth' });
    }
}

export default ({ data, location }) => {
    const {
        createdAt,
        updatedAt,
        title,
        ingress,
        readMoreText,
        heroImage,
        infoCards,
        //learningPathCards,
        //learningPathTableTitle,
        //learningPathTableItems,
        //formsTitle,
        //formItems,
    } = data.contentfulAcademy;

    const seoData = {
        updatedAt: updatedAt,
        createdAt: createdAt,
        title: title,
        author: "Frends team",
        image: heroImage.file.url,
    }

    const splitTitle = title.split(' ');

    return (
        <Layout
            title={title}
            transparentNavigation={true}
            seoData={seoData}
            location={location}
        >
            <main id="academy-content"
                style={{ marginTop: "-80px" }}
            >
                <section className="hero is-fullheight">
                    <GatsbyImage
                        image={getImage(heroImage)}
                        alt=""
                        style={{ width: "100%", height: "100%", position: "absolute", top: "0", left: "0" }}
                    />
                    <div className="hero-body">
                        <div className="container has-text-centered">
                            <h1 className="title size-100 margin-bottom-0">
                                <span className="is-white font-variable"
                                    style={{ lineHeight: '1' }}
                                >
                                    {splitTitle[0]}
                                </span>
                                <br />
                                <span className="font-bold is-transparent text-stroke-white"
                                    style={{ lineHeight: '1' }}
                                >
                                    {splitTitle[1]}
                                </span>
                            </h1>
                            <div className="is-flex is-justify-content-center margin-top-40 is-half is-offset-one-quarter">
                                <p className="is-white font-regular size-20 margin-bottom-10"
                                    style={{ maxWidth: '550px', lineHeight: '1.75' }}
                                >
                                    {ingress.ingress}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="container"
                        style={{ flexGrow: '0', marginBottom: '40px' }}
                    >
                        <a href="#training-types-and-learning-paths"
                            className="is-white is-flex is-flex-direction-column is-align-items-center"
                            onClick={(e) => handleLinkClick(e)}
                        >
                            <span className="size-18">
                                {readMoreText}
                            </span>
                            <svg width="10" height="38" viewBox="0 0 10 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill="currentColor"
                                    d="M5.20732 37.9235L9.54155 30.438L0.891822 30.4272L5.20732 37.9235ZM4.50505 0.468066L4.46666 31.1808L5.96484 31.1826L6.00323 0.469938L4.50505 0.468066Z"
                                />
                            </svg>
                        </a>
                    </div>
                </section>

                <section id="training-types-and-learning-paths"
                    className="section"
                    style={{ backgroundColor: '#F6FAF9' }}
                >
                    <div className="container is-flex is-justify-content-center">

                        <Masonry breakpointCols={{ default: 3, 1200: 2, 800: 1 }}
                            className="masonry-grid_academy"
                            columnClassName="masonry-grid_column_academy"
                            style={{ justifyContent: 'center' }}
                        >
                            {infoCards.map((infoItem, i) => (
                                <AcademyInfoCard key={i} infoItem={infoItem} />
                            ))}

                            {/*learningPathCards.map((pathItem, i) => (
                                <AcademyLearningPathCard key={i} pathItem={pathItem} />
                            ))*/}
                        </Masonry>
                    </div>
                </section>

                <section className="section">
                    <div className="container">
                        <GatsbyImage
                            image={getImage(data.suggested_path_img)}
                            alt="Suggested Training and Sertification Paths"
                            loading="lazy"
                        />
                    </div>
                </section>

                {/*<section id="learning-paths" className="section">
                    <div className="container">
                        <article>
                            <h2 className="is-purple size-30 academy-page--section-title">
                                {learningPathTableTitle}
                            </h2>

                            <AcademyLearningPathsTable learningPathTableItems={learningPathTableItems} />
                        </article>
                    </div>
                </section>*/}

                {/* {formsTitle && formItems && formItems.length > 0 && (
                    <section id="contact-forms" className="section">
                        <div className="container">
                            <article>
                                <h2 className="is-purple size-30 academy-page--section-title">
                                    {formsTitle}
                                </h2>

                                <AcademyContactForms formItems={formItems} />
                            </article>
                        </div>
                    </section>
                )} */}
            </main>
        </Layout>
    );
}

const AcademyInfoCard = ({ infoItem }) => {
    const { title, ingress, links } = infoItem;

    const splitTitle = title.split(' ');

    return (
        <article className="academy-info-card">
            <h2 className="is-purple">
                <span className="font-variable">
                    {splitTitle[0]}
                </span>
                <br />
                <span className="font-bold is-transparent text-stroke-purple">
                    {splitTitle[1]}
                </span>
            </h2>

            <p className="mt-5 is-grey-1">
                {ingress.ingress}
            </p>

            <ul className="mt-5">
                {links.map((link, i) => (
                    <li key={i}>
                        <a href={link.url} onClick={(e) => handleLinkClick(e)} >
                            <span className={`is-purple is-purple-border-bottom read-more`}>
                                {link.title}
                            </span>
                        </a>
                    </li>
                ))}
            </ul>
        </article>
    );
}

/*const AcademyLearningPathCard = ({ pathItem }) => {
    const {
        level,
        coverImage,
        coverTitle,
        title,
        keypoints,
        links
    } = pathItem;

    return (
        <article
            className="academy-learning-path-card"
        >
            <div className="background-picture-container"
                style={{ background: `url(${getSrc(coverImage)})` }}
            >
                <div>
                    <span
                        className="is-white size-38 font-variable"
                    >
                        L{level}
                    </span>
                    <br />
                    <span className="is-white size-20">
                        {coverTitle}
                    </span>
                </div>

            </div>

            <div className="content-container">
                <h2 className="is-purple">
                    Learning path 0{level}
                </h2>
                <h3 className="is-black">
                    {title}
                </h3>

                <ul>
                    {keypoints.map((keypoint, i) => (
                        <li key={i} className="is-grey-1">
                            {keypoint}
                        </li>
                    ))}
                </ul>

                <ul className="mt-4">
                    {links.map((link, i) => (
                        <li key={i}>
                            <a href={link.url} onClick={(e) => handleLinkClick(e)} >
                                <span className={`is-purple is-purple-border-bottom read-more`}>
                                    {link.title}
                                </span>
                            </a>
                        </li>
                    ))}
                </ul>
            </div>

        </article>
    );
}*/

/*const AcademyLearningPathsTable = ({ learningPathTableItems }) => (
    <table className="academy-page--learning-paths-table">
        <thead>
            <tr>
                <td className="col">
                    <div className="col-title-decreation">&nbsp;</div>
                    <span>Learning paths</span>
                </td>

                {learningPathTableItems.map((tableItem, i) => (
                    <th key={i} className="col">
                        <div className="col-title-decreation"
                            style={{ backgroundColor: tableItem.color }}
                        >
                            &nbsp;
                        </div>
                        <span>{tableItem.title}</span>
                    </th>
                ))}
            </tr>
        </thead>

        <tbody>
            <tr>
                <th span={learningPathTableItems.length + 1}
                    scope="rowgroup"
                    className="subtitle"
                >
                    Included Features
                </th>
            </tr>

            <AcademyLearningPathsTableFeatureRow
                title="Access to Online Courses"
                learningPathTableItems={learningPathTableItems}
                featureKey="accessToOnlineCourses"
            />
            <AcademyLearningPathsTableFeatureRow
                title="Access to Product Tours"
                learningPathTableItems={learningPathTableItems}
                featureKey="accessToProductTours"
            />
            <AcademyLearningPathsTableFeatureRow
                title="Access to Online Quizzes"
                learningPathTableItems={learningPathTableItems}
                featureKey="accessToOnlineQuizzes"
            />
            <AcademyLearningPathsTableFeatureRow
                title="Hosted Training"
                learningPathTableItems={learningPathTableItems}
                featureKey="hostedTraining"
            />
            <AcademyLearningPathsTableFeatureRow
                title="Training Assignments"
                learningPathTableItems={learningPathTableItems}
                featureKey="trainingAssignments"
            />
        </tbody>
    </table>
);*/

/*const AcademyLearningPathsTableFeatureRow = ({ title, learningPathTableItems, featureKey }) => (

    <tr>
        <th scope="row">
            {title}
        </th>

        {learningPathTableItems.map((tableItem, i) => (
            <td key={i} className="col">
                {tableItem?.includedFeatures?.[featureKey] ? (
                    <>
                        <p className="is-sr-only">Yes</p>
                        <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="5.73173" cy="5.54838" r="5.27958" fill="#AA8DFA" />
                        </svg>
                    </>
                ) : (
                    <>
                        <p className="is-sr-only">No</p>
                        <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="6.73173" cy="6.98807" r="5.27958" stroke="#979797" strokeWidth="1.5" />
                        </svg>
                    </>
                )}
            </td>
        ))}
    </tr>
);*/

const AcademyContactForms = ({ formItems }) => {
    const [showFormId, setShownFormId] = React.useState(formItems[0].hubspotFormId);

    const handleShownFormIdChange = (id) => {
        setShownFormId(null);
        setTimeout(() => setShownFormId(id), 0);
    }

    const handleAcademyContactFormSubmit = () => {
        pushToGTM({
            'event': 'frends_form_submit',
            'form_type': 'Services Frends Academy Form Submit',
            'resource_name': 'Services Frends Academy'
        });

        if (typeof window !== "undefined") {
            if (window.fbq != null) {
                window.fbq('track', 'CompleteRegistration')
            }
        }

        postFBEvent("CompleteRegistration", "Services Frends Academy Form")
    }

    return (
        <div className="academy-page--contacts-form">
            <form
                aria-controls="hubspot-form-container"
                aria-label="Select Hubspot contact form"
                onChange={(e) => handleShownFormIdChange(e.target.value)}
            >
                {formItems.map((formItem, i) => (
                    <React.Fragment key={i}>
                        <label htmlFor={`academy-form-option-${i}`}>
                            <input
                                type="radio"
                                name="academy-form"
                                id={`academy-form-option-${i}`}
                                value={formItem.hubspotFormId}
                                checked={formItem.hubspotFormId === showFormId}
                            />
                            <div className="label-container">
                                <div>{formItem.title}</div>

                                {formItem.description && (
                                    <div dangerouslySetInnerHTML={{
                                        __html: formItem.description.childrenMarkdownRemark[0].html
                                    }} />
                                )}
                            </div>
                        </label>
                    </React.Fragment>
                ))}
            </form>

            {showFormId && (
                <div id="hubspot-form-container">
                    <HubspotForm
                        formId={showFormId}
                        loading={<div>Loading form...</div>}
                        onSubmit={() => handleAcademyContactFormSubmit()}
                    />
                </div>
            )}
        </div>
    )
}

export const query = graphql`
    query ($id: String!) {
        contentfulAcademy(id: {eq: $id}) {
            updatedAt
            createdAt
            title
            ingress {
                ingress
            }
            readMoreText
            heroImage {
                gatsbyImageData(width: 1920)
                file {
                    url
                }
            }
            infoCards {
                title
                ingress {
                    ingress
                }
                links {
                    title
                    url
                }
            }
            learningPathCards {
                level
                coverImage {
                    gatsbyImageData(width: 340)
                }
                coverTitle
                title
                keypoints
                links {
                    url
                    title
                }
            }
            learningPathTableTitle
            learningPathTableItems {
                title
                color
                includedFeatures {
                    accessToOnlineCourses
                    accessToProductTours
                    accessToOnlineQuizzes
                    hostedTraining
                    trainingAssignments
                }
            }
        }
        suggested_path_img: file(relativePath: {eq: "images/TEMP_academy_suggested_paths.png"}) {
            childImageSharp {
              gatsbyImageData(width: 1344, quality: 100, layout: FULL_WIDTH)
            }
        }
    }  
`
